<template>
    <div class="single-file-alert alert">
        <div class="single-file-box alert_box">
            <div class="single-file-content">
                <p v-if="spaceInfo.paymentMethod === 'google'">앱에서 결제하신 경우, 해지신청은 Google Play 고객 센터(02-531-9000)를 통하여 해지신청을 해주시기 바랍니다.</p>
                <p v-else>앱에서 결제하신 경우, 해지신청은 Apple 고객센터(080-333-4000)를 통하여 해지신청을 해주시기 바랍니다.</p>
            </div>
            <div class="single-file-btn">
                <p id="color-text" @click="$emit('closePopup')">확인</p>
            </div>
        </div>
    </div>
</template>

<script>
export default {
  props: {
    spaceInfo: Object
  }
};
</script>

<style scoped>
  .single-file-content p:first-child { margin-bottom: 8px; }
</style>
